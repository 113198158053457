<template>
  <div class="grid row">
    <div class="flex xs12">
      <va-card title="Редагування меню" class="fill-height">
        <div>
          <div class="row">
            <div class="flex xs12">
              <va-tabs v-model="tabValue" style="width: 100%; min-width: 250px; margin-bottom: 25px;">
                <va-tab
                  v-for="title in tabTitles"
                  :key="title"
                >
                  {{title}}
                </va-tab>
              </va-tabs>
              <div class="flex xs4" v-if="tabValue === 0">
                <va-input
                  label="value"
                  v-model="value"
                  :messages="['The recommended number of characters is 1000']"
                  @input="delete errors.value"
                  :error="!!errors.value"
                  :error-messages="errors.value"
                />
                <va-input
                  label="label"
                  v-model="label"
                  :messages="['The recommended number of characters is 1000']"
                  @input="delete errors.label"
                  :error="!!errors.label"
                  :error-messages="errors.label"
                />
                <va-input
                  label="position"
                  v-model="position"
                  :messages="['The recommended number of characters is 15']"
                  @input="delete errors.position"
                  :error="!!errors.position"
                  :error-messages="errors.position"
                />
                <va-toggle label="in_contact" small v-model="in_contact"/>
                <va-toggle label="in_footer" small v-model="in_footer"/>
                <va-select v-model="type" :value="type.label" :options="selectValues" valueBy="id" textBy="label" />
                <va-button @click="submit()">Save</va-button>
              </div>
              <!-- <div class="flex xs12" v-if="tabValue === 1"> -->
              <!-- <va-input
                  label="Title"
                  v-model="seoTitle"
                />
                <va-input
                  label="Description"
                  v-model="seoDescription"
                />
                <va-input
                  label="Keywords"
                  v-model="seoKeywords"
                />
                <div style="margin-bottom: 15px;">
                  <ckeditor v-model="seoText" :editor="editor" :config="editorConfig"></ckeditor>
                </div> -->
              <!-- <span>Image</span> -->
              <!-- <va-card class="mb-3" title="Image">
                  <div v-if="seoImageExisting">
                    <a style="display: block;" target="_blank" :href="seoImageExisting"><img style="max-width: 400px;" :src="seoImageExisting"></a>
                    <div><va-button small color="danger" @click="seoImageExisting = deleteImage(seoImageId) ? '' : seoImageExisting">Видалити</va-button></div>
                  </div>
                  <span>Завантажити нове зображення</span>
                  <va-file-upload
                    type="gallery"
                    dropzone
                    v-model="seoImage"
                    style="margin-bottom: 25px;"
                  />
                  <va-button @click="uploadImage('image', 'SeoEntityImage', 'SeoEntity[image]', seoImage[0], 'common\\models\\SeoEntity')">Завантажити</va-button>
                </va-card> -->
              <!-- <va-button @click="submit()">Save</va-button> -->
              <!-- </div> -->
            </div>
          </div>
        </div>
      </va-card>
    </div>
  </div>
</template>

<script>
import * as axios from 'axios'
// import CKEditor from '@ckeditor/ckeditor5-vue'
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
// import '@ckeditor/ckeditor5-build-classic/build/translations/uk'
import Vue from 'vue'

export default {
  name: 'grid',
  // components: {
  //   ckeditor: CKEditor.component,
  // },
  data () {
    return {
    //   name: '',
    //   alias: '',
    //   position: '',
    //   published: false,

      //   seoId: '',
      //   seoTitle: '',
      //   seoDescription: '',
      //   seoKeywords: '',
      //   seoText: '',
      //   seoImage: [],
      //   seoImageExisting: '',
      //   seoImageId: '',
      // errors: [],
      // editor: ClassicEditor,
      // editorConfig: {
      // language: 'uk',
      // simpleUpload: {
      //     uploadUrl: `${process.env.VUE_APP_PRYLUKYAGRO_API_URL}/${this.locale}/editor/upload`,
      //     headers: {
      //     Authorization: localStorage.getItem('token'),
      //     },
      // },
      // },
      id: null,
      in_contact: null,
      in_footer: null,
      label: '',
      type: {
        id: '',
        label: '',
      },
      position: '',
      value: '',
      selectValues: [
        {
          label: 'address',
          id: 0,
        },
        {
          label: 'phone',
          id: 10,
        },
        {
          label: 'mail',
          id: 20,
        },
      ],
      errors: [],

      // tabTitles: ['Main', 'Seo'],
      tabTitles: ['Main'],
      tabValue: 0,
    }
  },
  created () {
    this.fetch()
  },
  computed: {
    computedStyle () {
      return {
        backgroundColor: this.$themes.primary,
      }
    },
    locale () {
      return Vue.i18n.locale()
    },
  },
  watch: {
    locale: function (value) {
      this.fetch()
    },
  },
  methods: {
    // uploadImage (attr, entityAttribute, param, file, model = '') {
    //   if (!file) {
    //     return
    //   }
    //   const formData = new FormData()
    //   formData.append('model_name', model)
    //   formData.append('attribute', attr)
    //   formData.append(param, file)
    //   formData.append('file_id', '0')
    //   formData.append('multiple', '0')
    //   formData.append('id', this.seoId)
    //   formData.append('entity_attribute', entityAttribute)

    //   axios.post(`${process.env.VUE_APP_PRYLUKYAGRO_API_URL}/${this.locale}/upload`, formData, {
    //     headers: {
    //       'Content-Type': 'multipart/form-data',
    //     },
    //   })
    //     .then(response => {
    //       this.showToast('Successful upload')
    //     })
    //     .catch(error => {
    //       console.log(error)
    //       this.showToast('Error')
    //     })
    // },
    // async deleteImage (id) {
    //   return axios.delete(`${process.env.VUE_APP_PRYLUKYAGRO_API_URL}/${this.locale}/upload/${id}`)
    //     .then(() => {
    //       this.showToast('Successfully deleted')
    //     })
    //     .catch(() => {
    //       this.showToast('Error')
    //     })
    // },
    submit () {
      axios.put(`${process.env.VUE_APP_PRYLUKYAGRO_API_URL}/${this.locale}/widgets/contacts/${this.$route.params.id}`, {
        main: {
          id: this.id,
          in_contact: this.in_contact,
          in_footer: this.in_footer,
          label: this.label,
          type: this.type.id,
          position: this.position,
          value: this.value,
        },
      })
        .then(response => {
          console.log(response)
          this.showToast('Success')
          this.$router.push({ name: 'prylukyagro-widgets-contacts' })
        })
        .catch(error => {
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          }
          console.log(error)
          this.showToast('Error')
        })
    },
    fetch () {
      axios.get(`${process.env.VUE_APP_PRYLUKYAGRO_API_URL}/${this.locale}/widgets/contacts/${this.$route.params.id}`)
        .then(response => {
          console.log(response.data.main)
          this.id = response.data.main.id
          this.in_contact = response.data.main.in_contact
          this.in_footer = response.data.main.in_footer
          this.label = response.data.main.label
          this.type.id = response.data.main.type
          this.type.label = this.selectValues.filter(item => {
            if (item.id === response.data.main.type) {
              return item.label
            }
          })[0].label
          this.position = response.data.main.position
          this.value = response.data.main.value
        })
        .catch(error => {
          console.log(error)
          this.showToast('Error')
        })
    },
  },
}
</script>

<style lang="scss">
.grid {
  &__container {
    min-height: 3rem;
    color: $white;
    border-radius: 0.5rem;
  }
}

.va-file-upload-gallery-item {
  flex-basis: unset;
  max-width: 400px;
  width: unset;
}
</style>
